/* Custom Zulip icons generated with webfont-loader */

@font-face {
    font-family: 'zulip-icons';
    src: url("files/zulip-icons.a059a8dc40e987f688fa.eot?#iefix") format("embedded-opentype"),
url("files/zulip-icons.a059a8dc40e987f688fa.woff") format("woff"),
url("files/zulip-icons.a059a8dc40e987f688fa.woff2") format("woff2"),
url("files/zulip-icons.a059a8dc40e987f688fa.ttf") format("truetype"),
url("files/zulip-icons.a059a8dc40e987f688fa.svg#zulip-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i.zulip-icon {
    font-family: 'zulip-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none;
    font-size: inherit;
    line-height: 100%;

    text-decoration: inherit;
    display: inline-block;
    speak: none;
}

.zulip-icon::before {
    font-family: 'zulip-icons' !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.zulip-icon.zulip-icon-arrow-left-circle::before {
    content: "\f101";
}
.zulip-icon.zulip-icon-close-small::before {
    content: "\f102";
}
.zulip-icon.zulip-icon-close::before {
    content: "\f103";
}
.zulip-icon.zulip-icon-search::before {
    content: "\f104";
}
.zulip-icon.zulip-icon-users::before {
    content: "\f105";
}
.zulip-icon.zulip-icon-account::before {
    content: "\f106";
}
.zulip-icon.zulip-icon-all-messages::before {
    content: "\f107";
}
.zulip-icon.zulip-icon-arrow-down::before {
    content: "\f108";
}
.zulip-icon.zulip-icon-at-sign::before {
    content: "\f109";
}
.zulip-icon.zulip-icon-bar-chart::before {
    content: "\f10a";
}
.zulip-icon.zulip-icon-bot::before {
    content: "\f10b";
}
.zulip-icon.zulip-icon-building::before {
    content: "\f10c";
}
.zulip-icon.zulip-icon-chevron-down::before {
    content: "\f10d";
}
.zulip-icon.zulip-icon-chevron-right::before {
    content: "\f10e";
}
.zulip-icon.zulip-icon-clock::before {
    content: "\f10f";
}
.zulip-icon.zulip-icon-credit-card::before {
    content: "\f110";
}
.zulip-icon.zulip-icon-drafts::before {
    content: "\f111";
}
.zulip-icon.zulip-icon-edit::before {
    content: "\f112";
}
.zulip-icon.zulip-icon-exclamation-circle::before {
    content: "\f113";
}
.zulip-icon.zulip-icon-f-globe::before {
    content: "\f114";
}
.zulip-icon.zulip-icon-file-text::before {
    content: "\f115";
}
.zulip-icon.zulip-icon-follow::before {
    content: "\f116";
}
.zulip-icon.zulip-icon-gear::before {
    content: "\f117";
}
.zulip-icon.zulip-icon-gif::before {
    content: "\f118";
}
.zulip-icon.zulip-icon-git-pull-request::before {
    content: "\f119";
}
.zulip-icon.zulip-icon-globe::before {
    content: "\f11a";
}
.zulip-icon.zulip-icon-hash::before {
    content: "\f11b";
}
.zulip-icon.zulip-icon-hashtag::before {
    content: "\f11c";
}
.zulip-icon.zulip-icon-heart::before {
    content: "\f11d";
}
.zulip-icon.zulip-icon-help::before {
    content: "\f11e";
}
.zulip-icon.zulip-icon-inbox::before {
    content: "\f11f";
}
.zulip-icon.zulip-icon-info::before {
    content: "\f120";
}
.zulip-icon.zulip-icon-inherit::before {
    content: "\f121";
}
.zulip-icon.zulip-icon-keyboard::before {
    content: "\f122";
}
.zulip-icon.zulip-icon-language::before {
    content: "\f123";
}
.zulip-icon.zulip-icon-life-buoy::before {
    content: "\f124";
}
.zulip-icon.zulip-icon-lock::before {
    content: "\f125";
}
.zulip-icon.zulip-icon-mail::before {
    content: "\f126";
}
.zulip-icon.zulip-icon-manage-search::before {
    content: "\f127";
}
.zulip-icon.zulip-icon-message-square::before {
    content: "\f128";
}
.zulip-icon.zulip-icon-monitor::before {
    content: "\f129";
}
.zulip-icon.zulip-icon-moon::before {
    content: "\f12a";
}
.zulip-icon.zulip-icon-more-vertical-spread::before {
    content: "\f12b";
}
.zulip-icon.zulip-icon-more-vertical::before {
    content: "\f12c";
}
.zulip-icon.zulip-icon-move-alt::before {
    content: "\f12d";
}
.zulip-icon.zulip-icon-mute-new::before {
    content: "\f12e";
}
.zulip-icon.zulip-icon-mute::before {
    content: "\f12f";
}
.zulip-icon.zulip-icon-play-circle::before {
    content: "\f130";
}
.zulip-icon.zulip-icon-popover-arrow::before {
    content: "\f131";
}
.zulip-icon.zulip-icon-power::before {
    content: "\f132";
}
.zulip-icon.zulip-icon-readreceipts::before {
    content: "\f133";
}
.zulip-icon.zulip-icon-scheduled-messages::before {
    content: "\f134";
}
.zulip-icon.zulip-icon-search-inbox::before {
    content: "\f135";
}
.zulip-icon.zulip-icon-send::before {
    content: "\f136";
}
.zulip-icon.zulip-icon-smart-toy::before {
    content: "\f137";
}
.zulip-icon.zulip-icon-smile::before {
    content: "\f138";
}
.zulip-icon.zulip-icon-source-alt::before {
    content: "\f139";
}
.zulip-icon.zulip-icon-star-filled::before {
    content: "\f13a";
}
.zulip-icon.zulip-icon-star::before {
    content: "\f13b";
}
.zulip-icon.zulip-icon-stop-circle::before {
    content: "\f13c";
}
.zulip-icon.zulip-icon-sun::before {
    content: "\f13d";
}
.zulip-icon.zulip-icon-tool::before {
    content: "\f13e";
}
.zulip-icon.zulip-icon-topic::before {
    content: "\f13f";
}
.zulip-icon.zulip-icon-triple-users::before {
    content: "\f140";
}
.zulip-icon.zulip-icon-unmute-new::before {
    content: "\f141";
}
.zulip-icon.zulip-icon-unmute::before {
    content: "\f142";
}
.zulip-icon.zulip-icon-unread::before {
    content: "\f143";
}
.zulip-icon.zulip-icon-user-plus::before {
    content: "\f144";
}
.zulip-icon.zulip-icon-user::before {
    content: "\f145";
}
.zulip-icon.zulip-icon-x-circle::before {
    content: "\f146";
}
